<script>
import appConfig from "@/app.config";

/**
 * Form Themes component
 */
export default {
  page: {
    title: "Form Themes",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { },
  data() {
    return {      
      form: {},
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-12 px-0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <h4 class="mb-3 header-title text-uppercase">
                  Escolha o tema do seu ava
                </h4>
              </div>
            </div>
            <div class="row d-flex justify-content-around">
              <div class="col-12 col-md-3 card rounded">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 text-center">
                      <input
                        type="radio"
                        v-model="form.theme"
                        id="themeAvaBlack"
                        name="theme"
                        value="ava-black"
                        checked="checked"
                      />
                      <label for="themeDefault">Ativo</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <img
                        src="https://sistema.speednow.com.br/public/images/themes/theme-ava-black.jpeg"
                        alt="Tema padrão"
                        class="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <span>Tema Moderno</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 card rounded">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 text-center">
                      <input
                        v-model="form.theme"
                        type="radio"
                        id="themeAvaBlackNetflix"
                        name="theme"
                        value="ava-black-netflix"
                      />
                      <label for="themeDefault">Ativar esse tema</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <img
                        src="https://sistema.speednow.com.br/public/images/themes/theme-ava-black-netflix.jpeg"
                        alt="Tema padrão"
                        class="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <span>Tema Flix</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 card rounded">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 text-center">
                      <input
                        v-model="form.theme"
                        type="radio"
                        id="themeDefault"
                        name="theme"
                        value="ava"
                      />
                      <label for="themeDefault">Ativar esse tema</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <img
                        src="https://sistema.speednow.com.br/public/images/themes/theme-default.jpeg"
                        alt="Tema padrão"
                        class="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <span>Tema Padrão</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>