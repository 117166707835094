const tableData = [
  {
    id: 1,
    banner: 'src/test.jpg',
    title: 'John Doe',
    status: 'A',
    link: 'teste.com.br',
  }, {
    id: 2,
    banner: 'src/test.jpg',
    title: 'Quality Engineer',
    status: 'I',
    link: 'teste.com.br',
  }, {
    id: 3,
    banner: 'src/test.jpg',
    title: 'Senior Editor',
    status: 'A',
    link: 'teste.com.br',
  }, {
    id: 4,
    banner: 'src/test.jpg',
    title: 'Programmer II',
    status: 'A',
    link: 'teste.com.br',
  },
]

export { tableData };
