<script>
import appConfig from "@/app.config";
import Switches from "vue-switches";

/**
 * Form Tabs component
 */
export default {
  page: {
    title: "Form Tabs",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Switches },
  data() {
    return {      
      form: {},
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Ativar ou Inativar Abas do AVA</h4>
          <p class="card-title-desc mb-4">
            <strong>Observação:</strong> Você pode alternar o botão para
            ativar ou desativar um recurso para acessar.
          </p>
          <form id="formUpdateSettingsAva">
            <table class="table table-striped table-centered mb-0">
              <thead>
                <tr>
                  <th>Página</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-top">
                  <td>Tutoria</td>
                  <td>
                    <switches
                      v-model="form.tutoring"
                      type-bold="true"
                      class="mb-0"
                      color="primary"
                    ></switches>
                  </td>
                </tr>
                <tr class="border-top">
                  <td>Aula ao Vivo</td>
                  <td>
                    <switches
                      v-model="form.liveClasses"
                      type-bold="true"
                      class="mb-0"
                      color="primary"
                    ></switches>
                  </td>
                </tr>
                <tr class="border-top">
                  <td>Certificados</td>
                  <td>
                    <switches
                      v-model="form.certificates"
                      type-bold="true"
                      class="mb-0"
                      color="primary"
                    ></switches>
                  </td>
                </tr>
                <tr class="border-top">
                  <td>Gamefication</td>
                  <td>
                    <switches
                      v-model="form.gamefication"
                      type-bold="true"
                      class="mb-0"
                      color="primary"
                    ></switches>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>