<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import FormTabs from "@/components/forms/form-ava/form-tabs/index";
import FormThemes from "@/components/forms/form-ava/form-themes/index";

import { tableData } from "./data-advancedtable";

/**
 * AVA Settings component
 */
export default {
  page: {
    title: "Configurações AVA",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormTabs, FormThemes },
  data() {
    return {
      tableData: tableData,
      title: "Configurações AVA",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Configurações AVA",
          active: true,
        },
      ],
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        title: "Título",
        banner: "Banner",
        link: "Link",
        status: "Status",
        actions: "Ações",
      },  
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "5%" } },
        { key: "title", sortable: true, label: this.fieldAliases.title, thStyle: { width: "220px" } },
        { key: "banner", sortable: true, label: this.fieldAliases.banner, thStyle: { width: "220px" } },
        { key: "link", sortable: true, label: this.fieldAliases.link },
        { key: "status", sortable: true, label: this.fieldAliases.status },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad() {
      this.$router.push('/category/create');
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <FormTabs />
    <FormThemes />
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Banners AVA | Tema Moderno</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- Coluna "banner" personalizada -->
                <template #cell(banner)="obj">
                  <div class="actions-cell">   
                    <b-img
                      :src="require('@/assets/images/small/img-3.jpg')"                      
                      :name="obj.item.banner"
                      width="200"
                      height="120"                      
                      alt="140x140"
                    ></b-img>
                  </div>
                </template>
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="obj">
                  <div class="actions-cell">                    
                    <b-button class="w-100" pill @click="deleteItem(obj.item)" variant="outline-primary">
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>     
    <!-- end row -->
  </Layout>
</template>
